import {
    Form,
    Modal,
    Select,
    Table,
    Input,
    Button,
    Space,
    notification,
    InputNumber
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { Fetch, Put } from '../../fetch'
import { convertIdToKey } from '../../utils'
import { Context } from '../../context'

const { Option } = Select
const { Column } = Table
const { confirm } = Modal

const ManagePlaylistGroups = () => {
    const { apiUrl, token } = useContext(Context)

    const [page, setPage] = useState("home")
    const [playlistGroups, setPlaylistGroups] = useState([])
    const [playlists, setPlaylists] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [modalType, setModalType] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [counter, setCounter] = useState(0)
    const [playlistId, setPlaylistId] = useState()

    const [form] = Form.useForm()

    useEffect(() => {
        if (page) {
            Fetch(`${apiUrl}/playlist-groups?page=${page}`, token, result => {
                const data = convertIdToKey(result.playlistGroups)
                setPlaylistGroups(data.filter(x => x.name !== 'Best of Seasons'))
            })
        }

        Fetch(`${apiUrl}/playlists?page=${page}`, token, (result) => {
            const data = convertIdToKey(result.playlists)
            setPlaylists(data)
        })
    }, [apiUrl, token, page, counter])

    const handleSubmit = async () => {
        setConfirmLoading(true)
        try {
            const fields = await form.validateFields()
            const duplicates = fields.content.filter((item, index) => fields.content.indexOf(item) !== index)
            if (duplicates.length > 0) {
              notification.error({
                message: 'Manage Playlists Error',
                description: 'Content has duplicates'
              })
              return
            }
            Put(
                `${apiUrl}/playlist-groups/${playlistId}`,
                token,
                { ...fields, page },
                result => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Playlists updated'
                        })
                    }
                    form.resetFields()
                    setModalVisible(false)
                    setConfirmLoading(false)
                    setCounter(counter + 1)
                },
                () => {
                    notification.error({
                        message: 'Manage Playlists Error',
                        description: 'Check console for more details'
                    })
                }
            )
        } catch (err) {

        } finally {
            setConfirmLoading(false)
        }
    }

    const showEditModal = (record) => {
        setModalType("Edit")
        setPlaylistId(record.key)

        const fields = record
        fields['content'] = record['content'].map(c => c._id)
        form.setFieldsValue(record)

        setModalVisible(true)
    }

    return (
      <div className="manage-section-wrapper">
        <Select
          placeholder="Select Page"
          showSearch
          onChange={(page) => setPage(page)}
          defaultValue={page}
        >
          <Option value="home">Home</Option>
        </Select>
        <Table dataSource={playlistGroups}>
          <Column title="Name" dataIndex="name" key="name" align="center" />
          <Column
            title="Actions"
            key="action"
            align="center"
            render={(text, record) => (
              <Space size="middle">
                <a
                  onClick={() => {
                    showEditModal(record);
                  }}
                >
                  Edit
                </a>
              </Space>
            )}
          />
        </Table>
        <Modal
          title={modalType + ' Playlist'}
          visible={modalVisible}
          onOk={handleSubmit}
          okText="Submit"
          onCancel={() => setModalVisible(false)}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form layout="vertical" form={form}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input disabled={true} />
            </Form.Item>
            <Form.List
              name="content"
              rules={[
                {
                  validator: async (_, content) => {
                    if (!content || content.length < 1) {
                      return Promise.reject(
                        new Error('At least 1 item required')
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 ? 'Content' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please select item or remove this field',
                          },
                        ]}
                        noStyle
                      >
                        <Select
                          showSearch
                          className="form-card-selector"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {playlists.map(p => (
                              <Option key={p.key} value={p.key}>
                                {p.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Add item
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      </div>
    );
}

export default ManagePlaylistGroups
