import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Switch } from "antd";
import moment from "moment";

import "./styles.css";

import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";
import {
    CheckCircleFilled,
    CloseCircleOutlined,
    CloudDownloadOutlined,
} from "@ant-design/icons";

const { Column } = Table;
const dateFormat = "MM-DD-YYYY";
const format = "YYYY-MM-DD"

const ManageRentals = () => {
    const { token, apiUrl } = useContext(Context);

    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filters, setFilters] = useState({
        date: [moment().startOf("month"), moment().endOf("month")],
        content_id: null,
        paid: null,
        currency: null,
    });
    const [games, setGames] = useState([]);

    const handleTableDataChange = (page) => {
        let f = filters;
        let moviestring = f.content_id ? `content_id=${f.content_id}&` : ``;
        let sdate = moment(f.date[0]).isValid()
            ? moment(f.date[0]).format(format)
            : null;
        let edate = moment(f.date[1]).isValid()
            ? moment(f.date[1]).format(format)
            : null;
        let datestring =
            sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
        let paidstring = f.paid ? `paid=${true}` : ``;
        let currencystring = f.currency ? `currency=${f.currency}` : ``;
        let fstring = f.content_id ? `${moviestring}${datestring}` : datestring;
        Fetch(
            `${apiUrl}/rentals?current=${page.current}&size=${page.pageSize}&${fstring}&${paidstring}&${currencystring}`,
            token,
            (result) => {
                const tickets = convertIdToKey(result.rentals);
                setTickets(tickets);
                setPagination((prev) => {
                    return {
                        ...page,
                        total: result.total,
                    };
                });
            }
        );
    };

    useEffect(() => {
        handleTableDataChange(pagination);
    }, [apiUrl, token, filters]);

    useEffect(() => {
        Fetch(`${apiUrl}/games?include_only_best=false&coming_soon=false`, token, (result) => {
            const game = convertIdToKey(result.games);
            setGames(game);
        });
    }, [apiUrl, token,]);

    const recheckPayment = (id) => {
        setLoading(true);

        Fetch(`${apiUrl}/tickets/${id}`, token, (result) => {
            if (result.paid) {
                setTickets(
                    tickets.map((t) => (t.key === id ? { ...t, paid: true } : t))
                );
            }
            setLoading(false);
        });
    };

    const extractGameName = (id) => {
        for (const g in games) {
            if (id === games[g].key) return games[g].name
        }
    }

    return (
        <div className="manage-orders-wrapper">
            <Row style={{ marginBottom: "20px" }}>
                <Col span={2}>
                    {" "}
                    Paid{" "}
                    <Switch
                        checked={filters.paid ? true : false}
                        onChange={(checked) =>
                            setFilters({ ...filters, paid: checked ? true : null })
                        }
                    />
                </Col>
                <Col span={6}>
                    <Select
                        style={{ width: "100%" }}
                        placeholder={"Filter by Content"}
                        showSearch
                        value={filters.content_id}
                        allowClear
                        onChange={(value) => setFilters({ ...filters, content_id: value })}
                    >
                        {games
                            .filter((doc) => doc.model === "rent")
                            .map((doc) => (
                                <Select.Option key={doc.key} value={doc.name}>
                                    {doc.name}{" "}
                                </Select.Option>
                            ))}
                    </Select>{" "}
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                    <DatePicker.RangePicker
                        style={{ width: "100%" }}
                        defaultValue={[
                            moment(filters.date[0], dateFormat),
                            moment(filters.date[1], dateFormat),
                        ]}
                        onChange={(value, dateString) =>
                            setFilters({
                                ...filters,
                                date: dateString ? dateString : [null, null],
                            })
                        }
                        format={dateFormat}
                    />
                </Col>
            </Row>
            <Table
                dataSource={tickets}
                pagination={pagination}
                onChange={handleTableDataChange}
            >
                <Column title="ID" dataIndex="key" key="key" align="center" />
                <Column title="User" dataIndex="user" key="language" align="center" />
                <Column
                    title="Date"
                    dataIndex="date"
                    key="date"
                    align="center"
                    render={(_, record) =>
                        moment(record.date).add(330, "minute").format(dateFormat)
                    }
                />
                <Column
                    title="End"
                    dataIndex="end"
                    key="end"
                    align="center"
                    render={(_, record) =>
                        moment(record.date)
                            .add(330, "minute")
                            .add(record.duration, "days")
                            .fromNow()
                    }
                />
                <Column title="Title" dataIndex="game_id" key="game_id" align="center" render={(_, record) => extractGameName(record.game_id)} />
                <Column
                    title="Amount"
                    dataIndex="amount"
                    key="amount"
                    align="center"
                    render={(_, record) => `$ ${record.amount / 100}`}
                />
                <Column
                    title="Paid"
                    dataIndex="paid"
                    key="paid"
                    align="center"
                    filters={[{ text: "Paid", value: true }]}
                    onFilter={(value, record) => record.paid === value}
                    render={(paid) =>
                        paid ? <CheckCircleFilled /> : <CloseCircleOutlined />
                    }
                />
                <Column
                    title="Actions"
                    align="center"
                    render={(_, record) =>
                        record.paid ? (
                            "-"
                        ) : (
                            <a onClick={() => recheckPayment(record.key)} disabled={loading}>
                                Recheck Status
                            </a>
                        )
                    }
                />
            </Table>
            <CloudDownloadOutlined
                className="export-btn-only"
                onClick={() => handleExportClick(apiUrl, token, "tickets")}
            />
        </div>
    );
};

export default ManageRentals;
