import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../context'

const UploadBanners = (props) => {
    const { token, apiUrl } = useContext(Context)
    const type = props.id
    const imgs = {
        cardImage: { w: 600, h: 400, ratio: '3:2', sw: 3 * 100, sh: 2 * 100, aspect: 1.5, },
        bannerImage: { w: 1920, h: 1080, ratio: '16:9', sw: 32 * 10, sh: 18 * 10, aspect: 1.7, },
        innerBannerImage: { w: 832, h: 468, ratio: '16:9', sw: 32 * 10, sh: 18 * 10, aspect: 1.7, },
        mobileImage: { w: 1600, h: 900, ratio: '16:9', sw: 16 * 20, sh: 9 * 20, aspect: 1.7, },
        iconImage: { w: 150, h: 150, ratio: '1:1', aspect: 1, }
    }
    const [fileList, setFileList] = useState([])

    useEffect(() => {
        if (props.value) {
            setFileList([{ url: props.value }])
        }
    }, [props, props.value])

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        try {
            // console.log(props)
            console.log(newFileList[0].response['url']);
            let fieldName = props.id
            // console.log(fieldName)
            // console.log(typeof (imgs[type]))
            props.updateForm.setFieldsValue({ [String(fieldName)]: newFileList[0].response['url'] });
            console.log("updated form")
            // console.log(props.updateForm.getFieldValue(String(fieldName)))
        }
        catch {
            return
        }
    };


    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const deleteFormItem = () => {
        props.updateForm.setFieldsValue({ [String(props.id)]: null })
    }

    return (
        <ImgCrop aspect={imgs[type].aspect}>
            <Upload
                action={`${apiUrl}/uploads`}
                headers={{
                    authorization: `Bearer ${token}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
                multiple={false}
                onRemove={deleteFormItem}
            >
                {fileList.length < 1 && '+ Upload'}
            </Upload>
        </ImgCrop >
    );
};
export default UploadBanners;