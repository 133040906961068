import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  Radio,
  Space,
  Select,
  notification
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import { Context } from '../../context'
import { Fetch, Post, Put, Delete } from '../../fetch'
import { convertIdToKey } from '../../utils'
import UploadBanners from '../UploadBanners'

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

const ManageGames = () => {
  const { token, apiUrl } = useContext(Context)

  const [games, setGames] = useState([])
  const [series, setSeries] = useState([])
  const [filteredSeries, setFilteredSeries] = useState([])
  const [teams, setTeams] = useState([])
  const [filteredTeams, setFilteredTeams] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [gameTypes, setGameTypes] = useState([])
  const [best, setBest] = useState(false)
  const [record, setRecord] = useState({})
  const [gamesMaster, setGamesMaster] = useState([])
  const [monetizationModel, setMonetizationModel] = useState('subscription')

  const [form] = Form.useForm()


  useEffect(() => {
    Fetch(`${apiUrl}/games`, token, (result) => {
      const data = convertIdToKey(result.games)
      data.forEach((game) => {
        if (!game.homeTeam) {
          game.homeTeam = "NA";
        }
        if (!game.awayTeam) {
          game.awayTeam = "NA";
        }
        if (!game.series) {
          game.series = "NA";
        }
      });
      setGames(data)
      setGamesMaster(data)
    })

    Fetch(`${apiUrl}/types`, token, (result) => {
      const data = convertIdToKey(result.types)
      setGameTypes(data)
    })

    Fetch(`${apiUrl}/series`, token, (result) => {
      const data = convertIdToKey(result.series)
      setSeries(data)
    })

    Fetch(`${apiUrl}/teams`, token, (result) => {
      const data = convertIdToKey(result.teams)
      setTeams(data)
    })
  }, [apiUrl, token, counter])

  const handleGameTypeChange = gameType => {
    setFilteredSeries(series.filter(x => x.gameType === gameType))
    setFilteredTeams(teams.filter(x => x.type === gameType))
  }

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true)
      const values = await form.validateFields()
      const fn = record.key ? Put : Post
      fn(
        record.key ? `${apiUrl}/games/${record.key}` : `${apiUrl}/games`,
        token,
        { ...values, bestInSeason: best },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: record.key ? 'Game edited' : 'Game created'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setCounter(counter + 1)
          setBest(false)
        },
        () => {
          notification.error({
            message: 'Add Game Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add Game Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const confirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this game?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/games/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Succes',
                description: 'Game deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting game',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  const editRecord = (record) => {
    setRecord(record)
    setFilteredSeries(series.filter(x => x.gameType === record.gameType))
    setFilteredTeams(teams.filter(x => x.type === record.gameType))
    const { homeTeam, awayTeam, dateTime, ...other } = record
    console.log(record)
    form.setFieldsValue({
      homeTeam: homeTeam['_id'],
      awayTeam: awayTeam['_id'],
      dateTime: moment(dateTime),
      ...other,
    })
    setBest(record.bestInSeason)
    setModalVisible(true)
  }

  const handleSearchChange = (gameId) => {
    if (!gameId)
      return

    setGames(gamesMaster.filter(p => p.key === gameId))
  }

  return (
    <div className="manage-section-wrapper">
      <Select
        placeholder="Search Games"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setGames(gamesMaster)}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
      >
        {gamesMaster.map(p => (
          <Option value={p.key}>{p.name}</Option>
        ))}
      </Select>
      <Table dataSource={games}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column title="Series" dataIndex="series" key="series" align="center" />
        <Column title="Home Team" dataIndex="homeTeam" key="homeTeam" align="center" render={t => t.name} />
        <Column title="Away Team" dataIndex="awayTeam" key="awayTeam" align="center" render={t => t.name} />
        <Column title="Date &amp; Time" dataIndex="dateTime" key="dateTime" align="center" render={(_, record) => record.dateTime ? moment(record.dateTime).format("MM-DD-YYYY") : ""} />
        <Column
          title="Best in Season"
          dataIndex="bestInSeason"
          key="bestInSeason"
          align="center"
          render={v => v ? 'Yes' : 'No'}
        />
        <Column
          title="Actions"
          key="actions"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editRecord(record)}>Edit</a>
              <a onClick={() => confirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>

      <PlusCircleTwoTone
        twoToneColor="#FF0000"
        style={{ fontSize: '48px' }}
        className="add-fab"
        onClick={() => setModalVisible(true)}
      />

      <Modal
        title={record.key ? "Edit Game" : "Add Game"}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => { setModalVisible(false); setBest(false); form.resetFields(); setRecord({}) }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="gameType" label="Type" rules={[{ required: true }]}>
            <Select onChange={handleGameTypeChange}>
              {gameTypes.map(t => (
                <Option value={t.name}>{t.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="series" label="Series" rules={[{ required: true }]}>
            <Select>
              {filteredSeries.map(s => (
                <Option value={s.name}>{s.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="homeTeam" label="Home Team" rules={[{ required: true }]}>
            <Select>
              {filteredTeams.map(t => (
                <Option value={t.key}>{t.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="awayTeam" label="Away Team" rules={[{ required: true }]}>
            <Select>
              {filteredTeams.map(t => (
                <Option value={t.key}>{t.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="dateTime" label="Date &amp; Time" rules={[{ required: true }]}>
            <DatePicker showTime placeholder="Select date and time" format={'MM-DD-YYYY HH:mm:ss'} />
          </Form.Item>
          <Form.Item name="monetizationModel" label="Monetization" rules={[{ required: true }]}>
            <Radio.Group
              value={monetizationModel}
              onChange={(e) => setMonetizationModel(e.target.value)}
            >
              <Radio value="subscription">Subscription</Radio>
              <Radio value="rent">Rent</Radio>
            </Radio.Group>
          </Form.Item>
          {monetizationModel === 'rent' && (<>
            <Form.Item name="price" label="Price" rules={[{ required: true }]}>
              <InputNumber
                min={1}
                max={30}
                placeholder="Enter price"
                style={{ 'width': '100%' }}
              />
            </Form.Item>
            <Form.Item name="duration" label="Stream Duration" rules={[{ required: true }]}>
              <InputNumber
                min={1}
                max={30}
                placeholder="Enter number of days (1-30)"
                style={{ 'width': '100%' }}
              />
            </Form.Item>
          </>)}
          <Form.Item name="cardImage" label="Card Image" rules={[{ required: true }]}>
            <UploadBanners name={"cardImage"} updateForm={form} />
          </Form.Item>
          <Checkbox onChange={(e) => setBest(e.target.checked)} checked={best}>Best in Season</Checkbox>
        </Form>
      </Modal>
    </div>
  )
}

export default ManageGames
