import { useState, useEffect, useContext } from 'react'
import {
  Modal,
  Table,
  Form,
  Input,
  InputNumber,
  notification,
  Space,
  Select,
  DatePicker
} from 'antd'
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import moment from 'moment';

import './styles.css'
import { Context } from '../../context'
import { Fetch, Post, Put, Delete } from '../../fetch'
import { convertIdToKey } from '../../utils'

const { Column } = Table
const { confirm } = Modal
const { Option } = Select

const displayDateFormat = 'MM-DD-YYYY';
const dateFormat = 'YYYY-MM-DD'

const ManageDiscounts = () => {
  const { apiUrl, token } = useContext(Context)

  const [discounts, setDiscounts] = useState()
  const [modalType, setModalType] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [discountId, setDiscountId] = useState()
  const [plans, setPlans] = useState([])

  const [form] = Form.useForm()

  useEffect(() => {
    Fetch(`${apiUrl}/discounts`, token, result => {
      const discounts = convertIdToKey(result.discounts)
      setDiscounts(discounts)
    })

    Fetch(`${apiUrl}/plans`, token, result => {
      const plans = convertIdToKey(result.plans)
      plans.push({ 'key': 'NA', 'name': 'All plans' })
      setPlans(plans)
    })
  }, [apiUrl, token, counter])

  const handleSubmit = async () => {
    setConfirmLoading(true)
    try {
      const values = await form.validateFields()
      const fn = modalType === 'Add' ? Post : Put
      fn(
        modalType === 'Add' ? `${apiUrl}/discounts` : `${apiUrl}/discounts/${discountId}`,
        token,
        values,
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Discounts updated'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setConfirmLoading(false)
          setCounter(counter + 1)
        },
        () => {
          notification.error({
            message: 'Discounts Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {

    } finally {
      setConfirmLoading(false)
    }
  }

  const showEditModal = (record) => {
    setModalType("Edit")
    setDiscountId(record.key)
    form.setFieldsValue(record)
    setModalVisible(true)
  }

  const showConfirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this discount?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/discounts/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Discount deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting discount',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  return (
    <div className="manage-section-wrapper">
      <Table dataSource={discounts}>
        <Column title="Code" dataIndex="code" key="code" align="center" />
        <Column
          title="Discount"
          dataIndex="amount"
          key="amount"
          render={(amount) => `$ ${amount}`}
          align="center"
        />
        <Column
          title="Usage"
          key="usage"
          render={(_, record) =>
            record.tokens
              ? `${record.tokensUsed ? record.tokensUsed : 0} of ${record.tokens
              }`
              : '-'
          }
          align="center"
        />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a
                onClick={() => {
                  showEditModal(record);
                }}
              >
                Edit
              </a>
              <a onClick={() => showConfirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      <PlusCircleTwoTone
        twoToneColor="#FF0091"
        style={{ fontSize: '48px' }}
        className="add-series-btn"
        onClick={() => {
          setModalVisible(true);
          setModalType('Add');
        }}
      />
      <Modal
        title={`${modalType} Discount`}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="code" label="Code" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Discount"
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="Enter value in $" style={{ width: "30%" }} />
          </Form.Item>
          <Form.Item name="tokens" label="Number of uses">
            <InputNumber
              placeholder="Enter number > 0"
              disabled={modalType === 'Edit'}
              style={{ width: "30%" }}
            />
          </Form.Item>
          <Form.Item name="minOrderValue" label="Minimum Order Value">
            <InputNumber
              placeholder="Enter number > 0"
              disabled={modalType === 'Edit'}
              style={{ width: "30%" }}
            />
          </Form.Item>
          <Form.Item
            name="applicableOn"
            label="Applicable on"
            rules={[{ required: true }]}
          >
            <Select className="form-card-selector">
              {plans.map((plan) => (
                <Option key={plan.key} value={plan.key}>
                  {plan.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="validFor"
            label="Valid For"
            rules={[{ required: true }]}
          >
            <Select className="form-card-selector" defaultValue="all">
              <Option key="all" value="all">
                All users
              </Option>
              <Option key="new" value="new">
                New users
              </Option>
            </Select>
          </Form.Item>
          <div className="datepicker-wrapper">
            <Form.Item
              name="startDate"
              label="Start Date"
              className="datepicker"
              format={displayDateFormat}
            >
              <DatePicker format={displayDateFormat} />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              className="datepicker"
              format={displayDateFormat}
            >
              <DatePicker format={displayDateFormat}/>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default ManageDiscounts
