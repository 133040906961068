import { Form, Input, Modal, Table, Space, Select, notification, Image } from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'

import { Context } from '../../context'
import { Fetch, Post, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

const ManageCoaches = () => {
    const { token, apiUrl } = useContext(Context)

    const [coaches, setCoaches] = useState([])
    const [coachesMaster, setCoachesMaster] = useState([])
    const [types, setTypes] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [counter, setCounter] = useState(0)
  
    const [form] = Form.useForm()
  
  
    useEffect(() => {
      Fetch(`${apiUrl}/coaches`, token, (result) => {
        const data = convertIdToKey(result.coaches)
        setCoaches(data)
        setCoachesMaster(data)
      })

      Fetch(`${apiUrl}/types`, token, (result) => {
        const data = convertIdToKey(result.types)
        setTypes(data)
      })
    }, [apiUrl, token, counter])
  
    const handleSubmit = async () => {
      try {
        setConfirmLoading(true)
        const fields = await form.validateFields()
        Post(
          `${apiUrl}/coaches`,
          token,
          {
            ...fields
          },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Coach created'
              })
            }
            form.resetFields()
            setModalVisible(false)
            setCounter(counter + 1)
          },
          () => {
            notification.error({
              message: 'Add Coach Error',
              description: 'Check console for more details'
            })
          }
        )
      } catch (err) {
        console.error(err)
        notification.error({
          message: 'Add Coach Error',
          description: 'Required fields are incorrect/missing'
        })
      } finally {
        setConfirmLoading(false)
      }
    }
  
    const confirmDelete = (id) => {
      confirm({
        title: 'Are you sure you want to delete this coach?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          Delete(
            `${apiUrl}/coaches/${id}`,
            token,
            (result) => {
              if (result.success) {
                notification.success({
                  message: 'Succes',
                  description: 'Coach deleted successfully'
                })
                setCounter(counter + 1)
              }
            },
            () => {
              notification.error({
                message: 'Error deleting coach',
                description: 'Please check console for more details'
              })
            }
          )
        }
      })
    }
  
    const handleSearchChange = (coachId) => {
      if (!coachId)
        return
  
      setCoaches(coachesMaster.filter(c => c.key === coachId))
    }
  
    return (
      <div className="manage-section-wrapper">
        <Select
          placeholder="Search Coaches"
          showSearch
          allowClear
          onChange={handleSearchChange}
          onClear={() => setCoaches(coachesMaster)}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {coachesMaster.map(c => (
            <Option value={c.key}>{c.name}</Option>
          ))}
        </Select>
        <Table dataSource={coaches}>
          <Column title="Name" dataIndex="name" key="name" align="center" />
          <Column title="Type" dataIndex="type" key="type" align="center" />
          <Column
            title="Actions"
            key="actions"
            align="center"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => confirmDelete(record.key)}>Delete</a>
              </Space>
            )}
          />
        </Table>
  
        <PlusCircleTwoTone
          twoToneColor="#FF0000"
          style={{ fontSize: '48px' }}
          className="add-fab"
          onClick={() => setModalVisible(true)}
        />
        <CloudDownloadOutlined
          className="export-btn"
          onClick={() => handleExportClick(apiUrl, token, 'coaches')}
        />
  
        <Modal
          title="Add Coach"
          visible={modalVisible}
          onOk={handleSubmit}
          okText="Submit"
          onCancel={() => setModalVisible(false)}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="type" label="Type" rules={[{ required: true }]}>
              <Select
                placeholder="Select Type"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {types.map(t => (
                  <Select.Option value={t.name}>{t.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
}

export default ManageCoaches
