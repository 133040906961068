import { Layout, Menu } from 'antd'
import { useState } from 'react'

import './styles.css'

import SideNav from '../SideNav'
import Dashboard from '../Dashboard'
import AddContent from '../AddContent'
import ManagePlans from '../ManagePlans'
import ManageUsers from '../ManageUsers'
import ManageOrders from '../ManageOrders'
import ManageRentals from '../ManageRentals'
import ManageBanners from '../ManageBanners'
import ManagePlaylists from '../ManagePlaylists'
import ManageDiscounts from '../ManageDiscounts'
import ManageEmployees from '../ManageEmployees'
import ManagePartners from '../ManagePartners'
import ManagePermissions from '../ManagePermissions'
import ManageTypes from '../ManageTypes'
import ManageSeries from '../ManageSeries'
import ManagePlayers from '../ManagePlayers'
import ManageCoaches from '../ManageCoaches'
import ManageTeams from '../ManageTeams'
import ManageGames from '../ManageGames'
import ManagePlaylistGroups from '../ManagePlaylistGroups'
import UserReports from '../Reports/userReports'
import ContentReports from '../Reports/contentReports'
import LikeReports from '../Reports/likeReports'

const { Header, Content } = Layout


const AdminModule = ({ userEmail }) => {
  const content = {
    "dashboard": <Dashboard />,
    "manage-plans": <ManagePlans />,
    "manage-users": <ManageUsers />,
    "manage-orders": <ManageOrders />,
    "manage-rentals": <ManageRentals />,
    "banners": <ManageBanners />,
    "playlists": <ManagePlaylists />,
    "manage-discounts": <ManageDiscounts />,
    "manage-employees": <ManageEmployees />,
    "manage-permissions": <ManagePermissions />,
    "manage-partners": <ManagePartners />,
    "manage-types": <ManageTypes />,
    "manage-players": <ManagePlayers />,
    "manage-coaches": <ManageCoaches />,
    "manage-teams": <ManageTeams />,
    "manage-series": <ManageSeries />,
    "manage-games": <ManageGames />,
    "playlist-groups": <ManagePlaylistGroups />,
    "user-reports": <UserReports />,
    "content-reports": <ContentReports />,
    "like-reports": <LikeReports />,
  }
  const [selectedTab, setSelectedTab] = useState("dashboard")

  const logout = async () => {
    await sessionStorage.removeItem('access_token')
    window.location.reload()
  }

  return (
    <>
      <Layout>
        <Header>
          <div className="header-content">
            <img
              src="/images/logo_dark_bg.jpg"
              alt="arclive-logo"
              className="header-logo"
            />
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1" onClick={logout} className="logout-btn">
                Logout
              </Menu.Item>
            </Menu>
          </div>
        </Header>
        <Layout className="site-layout">
          <SideNav updateTab={(tab) => setSelectedTab(tab)} userEmail={userEmail} />
          <Content>
            {content[selectedTab] ||
              'You do not have permissions to view this module'}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default AdminModule