import { useContext, useEffect, useState } from 'react'
import {
    Button,
    Select,
    Table,
    Checkbox,
    notification
} from 'antd'

import { Context } from "../../context"
import { Fetch, Patch } from "../../fetch"
import { convertIdToKey } from '../../utils'

const { Option } = Select
const { Column } = Table

const ManagePermissions = () => {
    const { apiUrl, token } = useContext(Context)

    const [employees, setEmployees] = useState([])
    const [counter, setCounter] = useState(0)
    const [permissions, setPermissions] = useState({})
    const [resources, setResources] = useState([])
    const [employeeId, setEmployeeId] = useState("")

    useEffect(() => {
        Fetch(`${apiUrl}/admins?filter=true`, token, (result) => {
            const employees = convertIdToKey(result.employees)
            setEmployees(employees)
        })
    }, [apiUrl, token])

    useEffect(() => {
        if (!employeeId)
            return

        Fetch(`${apiUrl}/admins/${employeeId}`, token, (result) => {
            const p = result.permissions
            setPermissions(p)
            setResources(Object.keys(p))
            setEmployeeId(employeeId)
        })
    }, [apiUrl, token, counter, employeeId])

    const handleChange = (resource, action, value) => {
        const p = Object.assign({}, permissions)
        p[resource][action] = value
        setPermissions(p)
    }

    const selectAll = () => {
        const p = Object.assign({}, permissions)
        for (let resource in p) {
            for (let action in p[resource]) {
                p[resource][action] = true
            }
        }
        setPermissions(p)
    }

    const clearAll = () => {
        const p = Object.assign({}, permissions)
        for (let resource in p) {
            for (let action in p[resource]) {
                p[resource][action] = false
            }
        }
        setPermissions(p)
    }

    const saveSelection = () => {
        Patch(
            `${apiUrl}/admins/${employeeId}`,
            token,
            { permissions },
            result => {
                if (result.success) {
                    notification.success({
                        message: 'Success',
                        description: 'Permissions updated'
                    })
                    setCounter(counter + 1)
                }
            },
            () => { }
        )
    }

    return (
        <div className="manage-section-wrapper">
            <Select
                placeholder="Select Employee"
                showSearch
                onChange={(id) => setEmployeeId(id)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filtersort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {employees.map(e => (
                    <Option value={e.key}>{e.name}</Option>
                ))}
            </Select>
            <Table dataSource={resources} pagination={false}>
                <Column title="Name" key="name" align="center" render={(_, name) => name} />
                <Column title="Read" key="read" dataIndex="read" align="center" render={(_, name) => <Checkbox checked={permissions[name]['read']} onChange={e => handleChange(name, 'read', e.target.checked)} />} />
                <Column title="Create" key="create" dataIndex="create" align="center" render={(_, name) => <Checkbox checked={permissions[name]['create']} onChange={e => handleChange(name, 'create', e.target.checked)} />} />
                <Column title="Update" key="update" dataIndex="update" align="center" render={(_, name) => <Checkbox checked={permissions[name]['update']} onChange={e => handleChange(name, 'update', e.target.checked)} />} />
                <Column title="Delete" key="delete" dataIndex="delete" align="center" render={(_, name) => <Checkbox checked={permissions[name]['delete']} onChange={e => handleChange(name, 'delete', e.target.checked)} />} />
            </Table>
            <Button type='primary' style={{ 'float': 'right', 'margin': '15px 5px' }} onClick={saveSelection}>Submit</Button>
            <Button type='dashed' style={{ 'float': 'right', 'margin': '15px 5px' }} onClick={clearAll}>Clear All</Button>
            <Button type='dashed' style={{ 'float': 'right', 'margin': '15px 5px' }} onClick={selectAll}>Select All</Button>
        </div>
    )
}

export default ManagePermissions
