import { Form, Input, Modal, Table, Space, Select, notification, Image } from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  PlayCircleOutlined,
  DeleteOutlined,
  PauseCircleOutlined
} from '@ant-design/icons'

import { Context } from '../../context'
import { Fetch, Post, Delete, Patch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import UploadBanners from '../UploadBanners/index'

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

const ManageTypes = () => {
  const { token, apiUrl } = useContext(Context)

  const [types, setTypes] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [typesMaster, setTypesMaster] = useState([])

  const [form] = Form.useForm()


  useEffect(() => {
    Fetch(`${apiUrl}/types`, token, (result) => {
      const data = convertIdToKey(result.types)
      setTypes(data)
      setTypesMaster(data)
    })
  }, [apiUrl, token, counter, confirmLoading])

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true)
      const fields = await form.validateFields()
      Post(
        `${apiUrl}/types`,
        token,
        {
          ...fields
        },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Type created'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setCounter(counter + 1)
        },
        () => {
          notification.error({
            message: 'Add Type Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add Type Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const confirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this type?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/types/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Succes',
                description: 'Type deleted successfully'
              })
              setCounter(counter + 1)
            } else {
              notification.warning({
                message: 'Not permitted',
                description: 'A game or series exists with this type'
              })
            }
          },
          () => {
            notification.error({
              message: 'Error deleting type',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  const handleSearchChange = (typeId) => {
    if (!typeId)
      return

    setTypes(typesMaster.filter(t => t.key === typeId))
  }

  const toggleState = (id) => {
    setConfirmLoading(true)
    Patch(`${apiUrl}/types/${id}`, token, (result) => {
      if (result.success) {
        notification.success({
          message: 'Succes',
          description: 'Game Type Toggled successfully'
        })
        setConfirmLoading(false)
      } else {
        notification.warning({
          message: 'Not permitted',
          description: 'A game or series exists with this type'
        })
        setConfirmLoading(false)
      }
    },
      () => {
        console.log("toggled")
      })
  }

  return (
    <div className="manage-section-wrapper">
      <Select
        placeholder="Search Type"
        showSearch
        allowClear
        onChange={handleSearchChange}
        onClear={() => setTypes(typesMaster)}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
      >
        {typesMaster.map(t => (
          <Option value={t.key}>{t.name}</Option>
        ))}
      </Select>
      <Table dataSource={types}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column title="Image" dataIndex="cardIamge" key="cardImage" align="center" render={(_, record) => record.cardImage ? <img src={record.cardImage} style={{ width: '250px' }} /> : null} />
        <Column
          title="Actions"
          key="actions"
          align="center"
          render={(_, record) => (
            <Space size="middle">
              <a onClick={() => toggleState(record.key)}>
                {record.active ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
              </a>
              <a onClick={() => confirmDelete(record.key)}><DeleteOutlined /></a>
            </Space>
          )}
        />
      </Table>

      <PlusCircleTwoTone
        twoToneColor="#FF0000"
        style={{ fontSize: '48px' }}
        className="add-fab"
        onClick={() => setModalVisible(true)}
      />

      <Modal
        title="Add Type"
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => { setModalVisible(false); form.resetFields() }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="cardImage" label="cardImage" rules={[{ required: true }]}>
            <UploadBanners name="cardImage" updateForm={form} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ManageTypes
