import { useState, useEffect, useContext } from 'react'
import {
  Table,
  InputNumber,
  Modal,
  Select,
  Radio,
  Form,
  Input,
  Upload,
  Space,
  notification,
  message
} from 'antd'
import { PlusCircleTwoTone, ExclamationCircleOutlined } from '@ant-design/icons'

import { Context } from '../../context'
import { Delete, Fetch, Patch, Put } from '../../fetch'
import { convertIdToKey } from '../../utils'
import Column from 'antd/lib/table/Column'
import UploadBanners from '../UploadBanners/index'

const { Option } = Select
const { confirm } = Modal

const ManageBanners = () => {
  const { apiUrl, token } = useContext(Context)

  const [form] = Form.useForm()
  const [page, setPage] = useState("home")
  const [banners, setBanners] = useState()
  const [bannerType, setBannerType] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [games, setGames] = useState([])
  const [series, setSeries] = useState([])
  const [bannerImageUrl, setBannerImageUrl] = useState()
  const [counter, setCounter] = useState(0)
  const [promoId, setPromoId] = useState()

  const handleChange = (page) => {
    setPage(page)
  }

  useEffect(() => {
    if (page) {
      Fetch(`${apiUrl}/promos?filter=${page}`, token, (result) => {
        const banners = convertIdToKey(result.promos)
        setBanners(banners)
      })
    }

    Fetch(`${apiUrl}/series`, token, (result) => {
      const data = convertIdToKey(result.series)
      setSeries(data)
    })

    Fetch(`${apiUrl}/games`, token, (result) => {
      const data = convertIdToKey(result.games)
      setGames(data)
    })
  }, [apiUrl, token, page, counter])

  const handleSubmit = async () => {
    setConfirmLoading(true)
    try {
      const values = await form.validateFields()
      const fn = modalType === 'Add' ? Patch : Put
      fn(
        modalType === 'Add' ? `${apiUrl}/promos` : `${apiUrl}/promos/${promoId}`,
        token,
        { ...values, page },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Banners updated'
            })
          }
          else {
            notification.error({
              message: 'Failed updating banner',
              description: 'Another Banner exists in the same position'
            })
          }
          form.resetFields()
          setModalVisible(false)
          setConfirmLoading(false)
          setCounter(counter + 1)
        },
        () => {
          notification.error({
            message: 'Edit Banners Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      notification.error({
        message: 'Edit Banners Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
      setBannerImageUrl("")
    }
  }

  const showConfirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this banner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/promos/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Promo deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting promo',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  const showEditModal = (record) => {
    setModalType("Edit")
    setPromoId(record.key)
    form.setFieldsValue(record)
    form.setFieldsValue('position', record.position)
    setBannerImageUrl(record.bannerImage)
    setModalVisible(true)

    if (page === 'home' || page === 'mhome') {
      setBannerType(record.promoType)
    }
  }

  const extractGameName = (record) => {
    if (record.promoType === "static")
      return "static"

    for (const g of games) {
      if (g.key === record.game)
        return g.name
    }

    for (const s of series) {
      if (s.key === record.series)
        return s.name
    }
  }

  return (
    <div className="manage-section-wrapper">
      <Select
        placeholder="Select Page"
        showSearch
        onChange={handleChange}
        value={page}
      >
        <Option value="home">Home</Option>
        <Option value="browse">Browse</Option>
        <Option value="exclusive">Exclusive</Option>
      </Select>
      <Table dataSource={banners}>
        {page === 'home' && <Column title="Promo Type" dataIndex="promoType" key="promoType" align="center" />}
        <Column title="Name" render={(_, record) => extractGameName(record)} align="center" />
        <Column title="Title" dataIndex="title" key="title" align='center' render={(_, record) => (record.title ? record.title : "-")} />
        <Column title="Description" dataIndex="description" key="description" align='center' render={(_, record) => (record.description ? record.description : "-")} />
        {page !== 'home' && <Column title="Banner" dataIndex="innerBannerImage" key="innerBannerImage" align="center" render={(value) => <img src={value} style={{ width: '250px' }} />} />}
        {page === 'home' && <Column title="Banner" dataIndex="bannerImage" key="bannerImage" align="center" render={(value) => <img src={value} style={{ width: '250px' }} />} />}
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => { showEditModal(record) }}>Edit</a>
              <a onClick={() => showConfirmDelete(record.key)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      <PlusCircleTwoTone twoToneColor="#FF0000" style={{ fontSize: "48px" }} className="add-series-btn" onClick={() => { setModalVisible(true); setModalType("Add") }} />
      <Modal
        title="Modify Banner"
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => setModalVisible(false)}
        confirmLoading={confirmLoading}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="promoType" label="Type" rules={[{ required: true }]}>
            <Radio.Group className="content-type-option" onChange={(e) => setBannerType(e.target.value)}>
              <Radio value="game">Game</Radio>
              <Radio value="series">Series</Radio>
              <Radio value="static">Static</Radio>
            </Radio.Group>
          </Form.Item>
          {(page === "game" || bannerType === "game") && (
            <Form.Item name="game" label="Select Game" rules={[{ required: true }]}>
              <Select
                showSearch
                className="form-card-selector"
              >
                {games.map(g => (
                  <Option key={g.key} value={g.key}>{g.name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {(page === "series" || bannerType === "series") && (
            <Form.Item name="series" label="Select Series" rules={[{ required: true }]}>
              <Select
                showSearch
                className="form-card-selector"
              >
                {series.map(s => (
                  <Option key={s.key} value={s.key}>{s.name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {page === "home" && (<Form.Item name="position" label="Position" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>)}
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input placeholder='Enter title' />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]} >
            <Input placeholder='Enter description' />
          </Form.Item>
          {page !== 'home' && (
            <Form.Item name="innerBannerImage" label="Banner" rules={[{ required: true }]}>
              <UploadBanners name="innerBannerImage" updateForm={form} />
            </Form.Item>)}
          {page === 'home' && (
            <Form.Item name="bannerImage" label="Banner" rules={[{ required: true }]}>
              <UploadBanners name="bannerImage" updateForm={form} />
            </Form.Item>)}
          <Form.Item name="mobileImage" label="Mobile Banner" rules={[{ required: true }]}>
            <UploadBanners name={"mobileImage"} updateForm={form} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ManageBanners
