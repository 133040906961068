import { Form, Button, Input, Modal, Table, notification, InputNumber, Checkbox, Space } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, MinusCircleOutlined, PlusOutlined, CloudDownloadOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'

import { Context } from '../../context'
import { Fetch, Post, Put, Delete, Patch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
const { Column } = Table

const ManagePlans = () => {
  const { token, apiUrl } = useContext(Context)

  const [plans, setPlans] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [planId, setPlanId] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    Fetch(`${apiUrl}/plans/admin`, token, (result) => {
      const plans = convertIdToKey(result.plans)

      plans.forEach(plan => {
        const type_of_isprivate = typeof (plan.isPrivate);

        if ((type_of_isprivate == "undefined") || (plan.isPrivate == "False")) {
          plan.isPrivate = "public";
        } else {
          plan.isPrivate = "private";
        }
      })
      setPlans(plans)
    }
    )

    if (!planId) {
      return
    }

    Fetch(`${apiUrl}/plans/${planId}`, token, (result) => {
      const { ...others } = result;
      console.log("hello");

      form.setFieldsValue(others);
    })
  }, [apiUrl, token, confirmLoading])

  const handleSubmit = async () => {
    try {
      setConfirmLoading(true)
      await form.validateFields()
      Post(
        `${apiUrl}/plans`,
        token,
        {
          name: form.getFieldValue('name'),
          price: form.getFieldValue('price'),
          internationalPrice: form.getFieldValue('internationalPrice'),
          duration: form.getFieldValue('duration'),
          isPrivate: form.getFieldValue('isPrivate'),
          features: form.getFieldValue('features')
        },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Plan created'
            })
          }
          form.resetFields()
          setModalVisible(false)
        },
        () => {
          notification.error({
            message: 'Add Plan Error',
            description: 'Check console for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Add Plan Error',
        description: 'Required fields are incorrect/missing'
      })
    } finally {
      setConfirmLoading(false)
    }
  }

  const getPlanDetails = async (id) => {

    Fetch(`${apiUrl}/plans/${id}`, token, (result) => {
      const { ...others } = result;

      form.setFieldsValue(others);
      setPlanId(id);
    })

  }

  const handleEditSubmit = async () => {
    try {
      await setConfirmLoading(true)
      const values = await form.validateFields()
      Put(
        `${apiUrl}/plans/${planId}`,
        token,
        { ...values },
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Edited successfully'
            })
            form.resetFields()
            setEditModalVisible(false)
            setPlanId(null)
          }
        },
        () => {
          notification.error({
            message: 'Error updating plan',
            description: 'Please check logs for more details'
          })
        }
      )
    } catch (err) {
      console.error(err)
      notification.error({
        message: 'Edit Movie Error',
        description: 'Required fields are incorrect/missing'
      })
    }
    finally {
      await setConfirmLoading(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await setConfirmLoading(true)
      Delete(
        `${apiUrl}/plans/${id}`,
        token,
        (result) => {
          if (result.success) {
            notification.success({
              message: 'Success',
              description: 'Plan deleted successfully'
            })
          }
        },
        () => {
          notification.error({
            message: 'Delete Plan Error',
            description: 'Check console for more details'
          })
        }
      )
    } finally {
      await setConfirmLoading(false)
    }
  }

  const toggleState = async (planId) => {
    const toggledFlag = !plans.filter(p => p.key === planId)[0].suspended
    try {
      await setConfirmLoading(true)
      Patch(
        `${apiUrl}/plans/${planId}`,
          token,
          {suspended: toggledFlag},
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Plan state toggled successfully'
              })
            }
          },
          () => {
            notification.error({
              message: 'Plan State Toggle Error',
              description: 'Check console for more details'
            })
          }
      )
    } finally {
      await setConfirmLoading(false)
    }
  }

  return (
    <div className="manage-section-wrapper">
      <Table dataSource={plans}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        <Column title="Price" dataIndex="price" key="price" align="center" />
        <Column
          title="Duration (days)"
          dataIndex="duration"
          key="duration"
          align="center"
        />
        <Column
          title="Status"
          dataIndex="suspended"
          key="suspended"
          align="center"
          render={v => v ? 'Suspended' : 'Active'}
        />
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a
                onClick={() => {
                  setPlanId(record.key);
                  getPlanDetails(record.key);
                  setEditModalVisible(true);
                  console.log(record.key, 'recordkey');
                }}
              >
                {<EditFilled />}
              </a>
              <a onClick={() => toggleState(record.key)}>
                {record.suspended ? <PlayCircleOutlined /> : <PauseCircleOutlined />}
              </a>
              {/* <a onClick={() => handleDelete(record.key)}>
                {<DeleteFilled />}
              </a> */}
            </Space>
          )}
        />
      </Table>
      <PlusCircleTwoTone
        twoToneColor="#FF0000"
        style={{ fontSize: '48px' }}
        className="add-fab"
        onClick={() => setModalVisible(true)}
      />
      <CloudDownloadOutlined
        className="export-btn"
        onClick={() => handleExportClick(apiUrl, token, 'plans')}
      />
      <Modal
        title="Add Plan"
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => {setModalVisible(false); form.resetFields()}}
        confirmLoading={confirmLoading}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical" initialValues={{ gender: 'M' }}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="Specify number of days" />
          </Form.Item>
          <Form.List
            name="features"
            rules={[
              {
                validator: async (_, features) => {
                  if (!features || features.length < 2)
                    return Promise.reject(new Error('At least 2 features'));
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? 'Features' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            'Please input feature or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input style={{ width: '95%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-btn"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add feature
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      <Modal
        title="Edit Plan"
        visible={editModalVisible}
        onOk={handleEditSubmit}
        okText="Update"
        onCancel={() => {setEditModalVisible(false); form.resetFields()}}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form form={form} layout="vertical" initialValues={{ gender: 'M' }}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="price" label="Price" rules={[{ required: true }]}>
            <InputNumber disabled = {true}/>
          </Form.Item>
          {/* <Form.Item
            name="internationalPrice"
            label="internationalPrice"
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item> */}
          <Form.Item
            name="duration"
            label="Duration"
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="Specify number of days" />
          </Form.Item>
          <Form.List
            name="features"
            rules={[
              {
                validator: async (_, features) => {
                  if (!features || features.length < 2)
                    return Promise.reject(new Error('At least 2 features'));
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? 'Features' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            'Please input feature or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input style={{ width: '95%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-btn"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add feature
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
}

export default ManagePlans
