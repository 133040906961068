export const convertIdToKey = (listOfObjects) => {
    return listOfObjects.map(obj => {
        const { '_id': key, ...rest } = obj
        return { key, ...rest }
    })
}

export const removeUnpublished = (listOfObjects) => {
    return listOfObjects.filter(obj => {
        return obj.availability === 'perpetual'
    })
}

export const convertSubscriptions = (listOfObjects) => {
    return listOfObjects.map(obj => {
        const { subscriptions, ...rest } = obj
        if (subscriptions) {
            const reversedSubscriptions = subscriptions.reverse()
            const subscription = `${reversedSubscriptions[0].name} (${reversedSubscriptions[0].startDate} to ${reversedSubscriptions[0].endDate})`
            return { subscription, ...rest }
        } else {
            return { subscription: 'None', ...rest }
        }
    })
}

export const handleExportClick = (apiUrl, token, content) => {
    fetch(`${apiUrl}/exports/${content}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.status)
        }
        return response.blob()
    }).then(blob => {
        let file = window.URL.createObjectURL(blob)
        let tab = window.open()
        tab.location.href = file
    }).catch(err => {
        console.error(err)
    })
}

export const findNameByKey = (arr, key) => {
    const match = arr.filter(a => a.key === key)
    if (match.length > 0) {
        return match[0].name
    }
    return ""
}
