import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

const { Column } = Table;
const dateFormat = "MM-DD-YYYY";
const { TabPane } = Tabs;

const LikeReports = () => {
    const { token, apiUrl } = useContext(Context);

    const [activeTab, setActiveTab] = useState("1");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filters, setFilters] = useState({
        date: [moment().startOf("month"), moment().endOf("month")],
        plan: null,
        paid: null,
        currency: null,
        model: null,
        country: null,
        platform: null,
        content: null,
    });
    const [plans, setPlans] = useState([]);

    const handleTableDataChange = (page) => {
        let f = filters;
        let planstring = f.plan ? `plan=${f.plan}` : ``;
        let sdate = moment(f.date[0]).isValid()
            ? moment(f.date[0]).format(dateFormat)
            : null;
        let edate = moment(f.date[1]).isValid()
            ? moment(f.date[1]).format(dateFormat)
            : null;
        let datestring =
            sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
        let paidstring = f.paid ? `paid=${true}` : ``;
        let currencystring = f.currency ? `currency=${f.currency}` : ``;
        let fstring = `${planstring}&${datestring}&${paidstring}&${currencystring}`;
    };

    useEffect(() => {
        handleTableDataChange(pagination);
    }, [apiUrl, token, filters]);

    const customfilters = (
        <>
            {" "}
            <Row style={{ marginBottom: "20px" }} align={"middle"}>
                {["1", "2", "3", "4", "5"].includes(activeTab) && (
                    <Col span={6}>
                        <DatePicker.RangePicker
                            style={{ width: "100%" }}
                            defaultValue={[
                                moment(filters.date[0], dateFormat),
                                moment(filters.date[1], dateFormat),
                            ]}
                            onChange={(value, dateString) =>
                                setFilters({
                                    ...filters,
                                    date: dateString ? dateString : [null, null],
                                })
                            }
                            format={dateFormat}
                        />
                    </Col>
                )}
                {["1"].includes(activeTab) && (
                    <Col span={4}>
                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            placeholder={"Search by Content"}
                            allowClear
                            onChange={(value) => setFilters({ ...filters, content: value })}
                        ></Select>
                    </Col>
                )}
            </Row>
        </>
    );

    const data1 = [
        { content: "Soccer Finals", likes: 90, dislikes: 10 },
        { content: "UEFA National", likes: 80, dislikes: 30 },
    ];

    return (
        <div className="manage-orders-wrapper">
            <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={(v) => setActiveTab(v)}
            >
                <TabPane tab="Likes Report" key="1">
                    {customfilters}
                    <Table
                        dataSource={data1}
                        pagination={pagination}
                        onChange={handleTableDataChange}
                    >
                        <Column
                            title="Content Title"
                            dataIndex="content"
                            key="content"
                            align="center"
                        />
                        <Column
                            title="Like count"
                            dataIndex="likes"
                            key="likes"
                            align="center"
                        />
                        <Column
                            title="Dislike count"
                            dataIndex="dislikes"
                            key="dislikes"
                            align="center"
                        />
                    </Table>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default LikeReports;