import { Form, Input, Modal, Table, Space, Select, Button, Tag, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import {
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'

import { Context } from '../../context'
import { Fetch, Post, Put, Delete } from '../../fetch'
import { convertIdToKey, handleExportClick, findNameByKey } from '../../utils'
import UploadBanners from '../UploadBanners/index'

const { Column } = Table;
const { confirm } = Modal;
const { Option } = Select;

const ManageTeams = () => {
    const { token, apiUrl } = useContext(Context)

    const [teams, setTeams] = useState([])
    const [teamsMaster, setTeamsMaster] = useState([])
    const [types, setTypes] = useState([])
    const [selectedType, setSelectedType] = useState("")
    const [modalVisible, setModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [counter, setCounter] = useState(0)
    const [allPlayers, setAllPlayers] = useState([])
    const [playersByType, setPlayersByType] = useState([])
    const [record, setRecord] = useState({})
  
    const [form] = Form.useForm()
  
    useEffect(() => {
      Fetch(`${apiUrl}/teams`, token, (result) => {
        const data = convertIdToKey(result.teams)
        setTeams(data)
        setTeamsMaster(data)
      })
      Fetch(`${apiUrl}/types`, token, (result) => {
        const data = convertIdToKey(result.types)
        setTypes(data)
      })
      Fetch(`${apiUrl}/players`, token, (result) => {
        const data = convertIdToKey(result.players)
        setAllPlayers(data)
      })
    }, [apiUrl, token, counter])
  
    useEffect(() => {
      setPlayersByType(allPlayers.filter(p => p.type === selectedType))
    }, [apiUrl, token, selectedType])

    const handleSubmit = async () => {
      try {
        setConfirmLoading(true)
        const fields = await form.validateFields()
        const fn = record.key ? Put : Post
        fn(
          record.key ? `${apiUrl}/teams/${record.key}` : `${apiUrl}/teams`,
          token,
          {
            ...fields
          },
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: record.key ? 'Team edited' : 'Team created'
              })
            }
            form.resetFields()
            setModalVisible(false)
            setCounter(counter + 1)
          },
          () => {
            notification.error({
              message: 'Add Team Error',
              description: 'Check console for more details'
            })
          }
        )
      } catch (err) {
        console.error(err)
        notification.error({
          message: 'Add Team Error',
          description: 'Required fields are incorrect/missing'
        })
      } finally {
        setConfirmLoading(false)
      }
    }
  
    const confirmDelete = (id) => {
      confirm({
        title: 'Are you sure you want to delete this team?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          Delete(
            `${apiUrl}/teams/${id}`,
            token,
            (result) => {
              if (result.success) {
                notification.success({
                  message: 'Succes',
                  description: 'Team deleted successfully'
                })
                setCounter(counter + 1)
              }
            },
            () => {
              notification.error({
                message: 'Error deleting team',
                description: 'Please check console for more details'
              })
            }
          )
        }
      })
    }
  
    const handleSearchChange = (teamId) => {
      if (!teamId)
        return
  
      setTeams(teamsMaster.filter(t => t.key === teamId))
    }
  
    const editRecord = (record) => {
      setRecord(record)
      setSelectedType(record.type)
      form.setFieldsValue(record)
      setModalVisible(true)
    }

    return (
      <div className="manage-section-wrapper">
        <Select
          placeholder="Search Team"
          showSearch
          allowClear
          onChange={handleSearchChange}
          onClear={() => setTeams(teamsMaster)}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        >
          {teamsMaster.map(t => (
            <Option value={t.key}>{t.name}</Option>
          ))}
        </Select>
        <Table dataSource={teams}>
          <Column title="Name" dataIndex="name" key="name" align="center" />
          <Column title="Type" dataIndex="type" key="type" align="center" />
          <Column title="Logo" dataIndex="iconImage" key="iconImage" align="center" render={(_, record) => record.iconImage ? <img src={record.iconImage} style={{ width: '250px' }} /> : null} />
          <Column
            title="Players"
            dataIndex="players"
            key="players"
            align="center"
            render={players => players.map(p => (
              <Tag color="blue">
                {findNameByKey(allPlayers, p)}
              </Tag>
            ))}
          />
          <Column
            title="Actions"
            key="actions"
            align="center"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => editRecord(record)}>Edit</a>
                <a onClick={() => confirmDelete(record.key)}>Delete</a>
              </Space>
            )}
          />
        </Table>
  
        <PlusCircleTwoTone
          twoToneColor="#FF0000"
          style={{ fontSize: '48px' }}
          className="add-fab"
          onClick={() => setModalVisible(true)}
        />
        <CloudDownloadOutlined
          className="export-btn"
          onClick={() => handleExportClick(apiUrl, token, 'teams')}
        />
  
        <Modal
          title={record.key ? "Edit Team" : "Add Team"}
          visible={modalVisible}
          onOk={handleSubmit}
          okText="Submit"
          onCancel={() => {setModalVisible(false); setRecord({}); form.resetFields()}}
          confirmLoading={confirmLoading}
          maskClosable={false}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="iconImage" label="Logo" rules={[{ required: true }]}>
            <UploadBanners name={"iconImage"} updateForm={form} />
          </Form.Item>
            <Form.Item name="type" label="Type" rules={[{ required: true }]}>
              <Select
                placeholder="Select Type"
                showSearch
                onChange={v => setSelectedType(v)}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
              >
                {types.map(t => (
                  <Select.Option value={t.name}>{t.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.List
              name="players"
              rules={[
                {
                  validator: async (_, players) => {
                    if (!players || players.length < 1) {
                      return Promise.reject(
                        new Error('At least 1 item required')
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 ? 'Players' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please select player or remove this field',
                          },
                        ]}
                        noStyle
                      >
                        <Select
                          showSearch
                          className="form-card-selector"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                        >
                          {playersByType.map(p => (
                            <Option key={p.key} value={p.key}>
                              {p.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Add player
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      </div>
    )
}

export default ManageTeams
