import { AudioTwoTone, ContactsTwoTone, ShoppingTwoTone, VideoCameraTwoTone } from '@ant-design/icons'
import { Row, Col, Card } from 'antd'
import { useEffect, useContext, useState } from 'react'

import { Context } from '../../context'
import { Fetch } from '../../fetch'

import './styles.css'

const Dashboard = () => {
    const {token, apiUrl} = useContext(Context)
    const [subscribers, setSubscribers] = useState(0)
    const [ordersMTD, setOrdersMTD] = useState(0)
    const [moviesAndSeries, setMoviesAndSeries] = useState(0)

    useEffect(() => {
        Fetch(`${apiUrl}/admins/dashboard`, token, (result) => {
            setSubscribers(result.subscribers)
            setOrdersMTD(result.ordersMTD)
            //setMoviesAndSeries(result.moviesAndSeries)
        })
    }, [apiUrl, token])

    return (
        <>
            <Row>
                <Col span={6}>
                    <Card className="info-card">
                        <ContactsTwoTone twoToneColor="#e65251" style={{ fontSize: '3em'}} />
                        <div className="info-card-metric">
                            <p>Total Subscribers</p>
                            <h3>{subscribers}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <ContactsTwoTone twoToneColor="#00ce68" style={{ fontSize: '3em'}} />
                        <div className="info-card-metric">
                            <p>Active Subscribers</p>
                            <h3>{subscribers}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <ShoppingTwoTone twoToneColor="#ffaf00" style={{ fontSize: '3em' }} />
                        <div className="info-card-metric">
                            <p>Orders (MTD)</p>
                            <h3>{ordersMTD}</h3>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card className="info-card">
                        <VideoCameraTwoTone twoToneColor="#3944bc" style={{ fontSize: '3em'}} />
                        <div className="info-card-metric">
                            <p>Content</p>
                            <h3>{moviesAndSeries}</h3>
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Dashboard
