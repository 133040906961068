import { useContext, useEffect, useState } from "react";
import { DatePicker, Select, Table, Row, Col, Tabs, Tag, Input } from "antd";
import moment from "moment";
import { Context } from "../../context";
import { Fetch } from "../../fetch";
import { convertIdToKey, handleExportClick } from "../../utils";

const { Column } = Table;
const dateFormat = "MM-DD-YYYY";
const { TabPane } = Tabs;

const UserReports = () => {
  const { token, apiUrl } = useContext(Context);

  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [filters, setFilters] = useState({
    date: [moment().startOf("month"), moment().endOf("month")],
    plan: null,
    paid: null,
    currency: null,
    model: "SUBSCRIPTION",
    country: null,
    platform: null,
    singleDate: moment().startOf("month"),
  });
  const [plans, setPlans] = useState([]);

  const handleTableDataChange = (page) => {
    let f = filters;
    let planstring = f.plan ? `plan=${f.plan}` : ``;
    let sdate = moment(f.date[0]).isValid()
      ? moment(f.date[0]).format(dateFormat)
      : null;
    let edate = moment(f.date[1]).isValid()
      ? moment(f.date[1]).format(dateFormat)
      : null;
    let datestring =
      sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
    let paidstring = f.paid ? `paid=${true}` : ``;
    let currencystring = f.currency ? `currency=${f.currency}` : ``;
    let fstring = `${planstring}&${datestring}&${paidstring}&${currencystring}`;
  };

  useEffect(() => {
    handleTableDataChange(pagination);
  }, [apiUrl, token, filters]);

  const customfilters = (
    <>
      {" "}
      <Row style={{ marginBottom: "20px" }} align={"middle"}>
        {["2"].includes(activeTab) && (
          <Col span={6}>
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              defaultValue={[
                moment(filters.date[0], dateFormat),
                moment(filters.date[1], dateFormat),
              ]}
              onChange={(value, dateString) =>
                setFilters({
                  ...filters,
                  date: dateString ? dateString : [null, null],
                })
              }
              format={dateFormat}
            />
          </Col>
        )}
        {["1", "5", "4", "3", "6"].includes(activeTab) && (
          <Col span={6}>
            <DatePicker
              style={{ width: "100%" }}
              onChange={(value, dateString) =>
                setFilters({
                  ...filters,
                  singleDate: dateString ? dateString : null,
                })
              }
              format={dateFormat}
            />
          </Col>
        )}
        {["3"].includes(activeTab) && (
          <>
          </>
        )}
        {["1", "4"].includes(activeTab) && (
          <Col span={4}>
            <Select
              style={{ width: "100%" }}
              placeholder={"User Model "}
              allowClear
              onChange={(value) => setFilters({ ...filters, model: value })}
            >
              {["SUBSCRIPTION", "RENTALS"].map((code) => (
                <Select.Option key={code} value={code}>
                  {code}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
        {["1", "2"].includes(activeTab) && (
          <Col
            span={4}
            align="right"
            push={activeTab == "1" ? 8 : 12}
            style={{ fontSize: 18 }}
          >
            {filters.model === 'SUBSCRIPTION' ? `    Subscribers - ${0}` : `   Rentals - ${0}`}
          </Col>
        )}
      </Row>
    </>
  );

  const data1 = [
    { userId: "6fdgdfg34tfd7", source: "facebook", content: "UEFA National" },
    {
      userId: "6fdgdfg3fd7345",
      source: "instagram",
      content: "Soccer Finals",
    },
  ];

  const data2 = [
    { userId: "6fdgdfg34tfd7", source: "facebook", content: "movie_id1" },
    { userId: "6fdgdfg3fd7345", source: "instagram", content: "series_id" },
  ];

  const data3 = [
    {
      date: "20-06-2022",
      DAU: 10,
      WAU: 25,
      MAU: 100,
      UU: 20,
      VV: 80,
      ER: 0.1,
    },
    {
      date: "21-06-2022",
      DAU: 12,
      WAU: 345,
      MAU: 120,
      UU: 20,
      VV: 50,
      ER: 0.1,
    },
  ];

  const data4 = [
    { date: "20-06-2022", userCount: 8, repeatUserCount: 5 },
    { date: "21-06-2022", userCount: 10, repeatUserCount: 8 },
  ];

  const data5 = [
    { date: "20-06-2022", hour: 1, hau: 30 },
    { date: "20-06-2022", hour: 8, hau: 25 },
    { date: "20-06-2022", hour: 10, hau: 30 },
    { date: "20-06-2022", hour: 24, hau: 30 },
  ];

  const data6 = [
    { user: "user_id_1", tsd: 225 },
    { user: "user_id_2", tsd: 100 },
  ];

  const data7 = [
    {
      subscriptionRevenue: 100,
      rentalRevenue: 20,
      renewalRevenue: 20,
      totalRevenue: 120,
      arpu: 10,
    },
    {
      subscriptionRevenue: 500,
      rentalRevenue: 200,
      renewalRevenue: 200,
      totalRevenue: 700,
      arpu: 50,
    },
  ];

  return (
    <div className="manage-orders-wrapper">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(v) => setActiveTab(v)}
      >
        <TabPane tab="Subscriptions/Rentals" key="1">
          {customfilters}
          <Table dataSource={data1}>
            <Column
              title="User Id"
              dataIndex="userId"
              key="userId"
              align="center"
            />
            <Column
              title="Source/medium"
              dataIndex="source"
              key="source"
              align="center"
            />
            <Column
              title="Content clicked for Purchase"
              dataIndex="content"
              key="content"
              align="center"
            />
          </Table>
        </TabPane>
        <TabPane tab="Revenue Reports" key="6">
          {customfilters}
          <Table dataSource={data7}>
            <Column
              title="Subscription Rev"
              dataIndex="subscriptionRevenue"
              key="subscriptionRevenue"
              align="center"
            />
            <Column
              title="Rentals Rev"
              dataIndex="rentalRevenue"
              key="rentalRevenue"
              align="center"
            />
            <Column
              title="Renewal Revenue"
              dataIndex="renewalRevenue"
              key="renewalRevenue"
              align="center"
            />
            <Column
              title="Total Revenue"
              dataIndex="totalRevenue"
              key="totalRevenue"
              align="center"
            />
            <Column title="ARPU" dataIndex="arpu" key="arpu" align="center" />
          </Table>
        </TabPane>
        <TabPane tab="Active Users" key="3">
          {customfilters}
          <Table
            dataSource={data3}
            pagination={pagination}
            onChange={handleTableDataChange}
          >
            <Column title="DAU" dataIndex="DAU" key="DAU" align="center" />
            <Column title="WAU" dataIndex="WAU" key="WAU" align="center" />
            <Column title="MAU" dataIndex="MAU" key="MAU" align="center" />
            <Column title="UU" dataIndex="UU" key="UU" align="center" />
            <Column
              title="Video views"
              dataIndex="VV"
              key="VV"
              align="center"
            />
            <Column
              title="ER = DAU/MAU"
              dataIndex="ER"
              key="ER"
              align="center"
            />
          </Table>
        </TabPane>
        <TabPane tab="New vs Repeat Users" key="4">
          {customfilters}
          <Table
            dataSource={data4}
            pagination={pagination}
            onChange={handleTableDataChange}
          >
            <Column
              title="New User Count"
              dataIndex="userCount"
              align="center"
            />
            <Column
              title="Repeat User Count"
              dataIndex="repeatUserCount"
              key="repeatUserCount"
              align="center"
            />
          </Table>
        </TabPane>
        <TabPane tab="Hourly Active users" key="5">
          {customfilters}
          <Table dataSource={data5}>
            <Column
              title="Hour (1 - 24)"
              dataIndex="hour"
              key="hour"
              align="center"
            />
            <Column title="HAU" dataIndex="hau" key="hau" align="center" />
          </Table>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserReports;