import {
  Form,
  Modal,
  Select,
  Table,
  Radio,
  Input,
  Button,
  Space,
  notification,
  InputNumber
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { PlusCircleTwoTone, MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { Fetch, Post, Put, Delete } from '../../fetch'
import { convertIdToKey, removeUnpublished } from '../../utils'
import { Context } from '../../context'
import UploadBanners from '../UploadBanners'

const { Option } = Select
const { Column } = Table
const { confirm } = Modal

const ManagePlaylists = () => {
  const { apiUrl, token } = useContext(Context)

  const [page, setPage] = useState("home")
  const [playlists, setPlaylists] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  const [games, setGames] = useState([])
  const [playlistId, setPlaylistId] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    if (page) {
      Fetch(`${apiUrl}/playlists?page=${page}`, token, result => {
        const playlists = convertIdToKey(result.playlists)
        setPlaylists(playlists)
      })
    }

    Fetch(`${apiUrl}/games`, token, (result) => {
      const data = convertIdToKey(result.games)
      setGames(data)
    })
  }, [apiUrl, token, page, counter])

  const handleSubmit = async () => {
    setConfirmLoading(true)
    try {
      const fields = await form.validateFields()
      const duplicates = fields.content.filter((item, index) => fields.content.indexOf(item) !== index)
      if (duplicates.length > 0) {
        notification.error({
          message: 'Manage Playlists Error',
          description: 'Content has duplicates'
        })
        return
      }
      const fn = modalType === 'Add' ? Post : Put
      let name = form.getFieldValue('name')
      let add = true
      playlists.forEach((p) => {
        if (p.name === name && modalType === 'Add') {
          notification.error({
            message: 'Failed',
            description: 'Another Playlist exists with the same name'
          })
          add = false
        }
      })
      if (add) {
        fn(
          modalType === 'Add' ? `${apiUrl}/playlists` : `${apiUrl}/playlists/${playlistId}`,
          token,
          { ...fields, page },
          result => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Playlists updated'
              })
            }
            form.resetFields()
            setModalVisible(false)
            setConfirmLoading(false)
            setCounter(counter + 1)
          },
          () => {
            notification.error({
              message: 'Manage Playlists Error',
              description: 'Check console for more details'
            })
          }
        )
      }
    } catch (err) {

    } finally {
      setConfirmLoading(false)
    }
  }

  const showEditModal = (record) => {
    setModalType("Edit")
    setPlaylistId(record.key)

    const fields = record
    fields['content'] = record['content'].map(c => c._id)
    form.setFieldsValue(fields)

    setModalVisible(true)
  }

  const showConfirmDelete = (id) => {
    confirm({
      title: 'Are you sure you want to delete this playlist?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        Delete(
          `${apiUrl}/playlists/${id}`,
          token,
          (result) => {
            if (result.success) {
              notification.success({
                message: 'Success',
                description: 'Playlist deleted successfully'
              })
              setCounter(counter + 1)
            }
          },
          () => {
            notification.error({
              message: 'Error deleting playlist',
              description: 'Please check console for more details'
            })
          }
        )
      }
    })
  }

  return (
    <div className="manage-section-wrapper">
      <Select
        placeholder="Select Page"
        showSearch
        onChange={(page) => setPage(page)}
        value={page}
      >
        <Option value="home">Home</Option>
        <Option value="browse">Browse</Option>
        <Option value="exclusive">Exclusive</Option>
      </Select>
      <Table dataSource={playlists}>
        <Column title="Name" dataIndex="name" key="name" align="center" />
        {page !== 'home' && <Column
          title="Position"
          dataIndex="position"
          key="position"
          align="center"
        />}
        <Column
          title="Actions"
          key="action"
          align="center"
          render={(text, record) => (
            <Space size="middle">
              <a
                onClick={() => {
                  showEditModal(record);
                }}
              >
                Edit
              </a>
              {page !== 'search' && <a onClick={() => showConfirmDelete(record.key)}>Delete</a>}
            </Space>
          )}
        />
      </Table>
      {page !== 'search' && <PlusCircleTwoTone
        twoToneColor="#FF0000"
        style={{ fontSize: '48px' }}
        className="add-series-btn"
        onClick={() => {
          setModalVisible(true);
          setModalType('Add');
          form.resetFields();
        }}
      />}
      <Modal
        title={modalType + ' Playlist'}
        visible={modalVisible}
        onOk={handleSubmit}
        okText="Submit"
        onCancel={() => setModalVisible(false)}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <Form layout="vertical" form={form}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {page !== 'home' && <Form.Item
            name="position"
            label="Position"
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>}
          <Form.Item name="cardImage" label="Card Image" rules={[{ required: true }]}>
            <UploadBanners name={"cardImage"} updateForm={form} />
          </Form.Item>
          <Form.List
            name="content"
            rules={[
              {
                validator: async (_, content) => {
                  if (!content || content.length < 1) {
                    return Promise.reject(
                      new Error('At least 1 item required')
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? 'Content' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Please select item or remove this field',
                        },
                      ]}
                      noStyle
                    >
                      <Select
                        showSearch
                        className="form-card-selector"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {games.map(g => (
                          <Option key={g.key} value={g.key}>
                            {g.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Add item
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
}

export default ManagePlaylists
