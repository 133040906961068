import { useContext, useEffect, useState } from 'react'
import { DatePicker, Select, Table, Row, Col, message, Switch } from 'antd'
import moment from 'moment';

import './styles.css'

import { Context } from '../../context'
import { Fetch } from '../../fetch'
import { convertIdToKey, handleExportClick } from '../../utils'
import { CheckCircleFilled, CloseCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons'

const { Column } = Table
const displayDateFormat = 'MM-DD-YYYY HH:mm:ss';
const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const ManageOrders = () => {
    const { token, apiUrl } = useContext(Context)

    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [filters, setFilters] = useState({ date: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)], plan: null, paid: null });
    const [plans, setPlans] = useState([]);

    const handleTableDataChange = (page) => {
        let f = filters;
        let planstring = f.plan ? `plan=${f.plan}` : ``;
        let sdate = moment(f.date[0]).isValid() ? moment(f.date[0]).format(dateFormat) : null
        let edate = moment(f.date[1]).isValid() ? moment(f.date[1]).format(dateFormat) : null
        let datestring = sdate && edate ? `start_date=${sdate}&end_date=${edate}` : ``;
        let paidstring = f.paid ? `paid=${true}` : ``;
        let fstring = `${planstring}&${datestring}&${paidstring}`

        Fetch(
            `${apiUrl}/orders?current=${page.current}&size=${page.pageSize}&${fstring}`,
            token,
            (result) => {
                const orders = convertIdToKey(result.orders)
                setOrders(orders)
                setPagination(prev => {
                    return {
                        ...page,
                        total: result.total
                    }
                })
            }
        )
    }


    useEffect(() => {
        handleTableDataChange(pagination)
        if ('length' in plans && plans.length === 0) {
            Fetch(
                `${apiUrl}/plans/admin`, token, (result) => {
                    const plans = convertIdToKey(result.plans)

                    plans.forEach(plan => {
                        const type_of_isprivate = typeof (plan.isPrivate);

                        if ((type_of_isprivate == "undefined") || (plan.isPrivate == "False")) {
                            plan.isPrivate = "public";
                        } else {
                            plan.isPrivate = "private";
                        }
                    })
                    setPlans(plans)
                })

        }
    }, [apiUrl, token, filters])

    const recheckPayment = (id) => {
        setLoading(true)
        let url = `${apiUrl}/pporders/${id}`;
        if (id && id.includes('order_'))
            url = `${apiUrl}/orders/${id}`;
        Fetch(url,
            token,
            (result) => {
                if (result.paid) {
                    setOrders(orders.map(order => order.key === id ? { ...order, paid: true } : order))
                }
                setLoading(false)
            }
        )
    }



    return (
        <div className="manage-orders-wrapper">
            <Row style={{ marginBottom: "20px" }} >
                <Col span={2} > Paid <Switch checked={filters.paid ? true : false}
                    onChange={(checked) => setFilters({ ...filters, paid: checked ? true : '' })} />
                </Col>
                <Col span={6}>
                    <Select style={{ width: "100%" }}
                        placeholder={"Filter by Plan"} showSearch value={filters.plan}
                        allowClear onChange={(value) => setFilters({ ...filters, plan: value })}>
                        {plans.map((plan, index) => {
                            return <Select.Option key={index} value={plan.name}  >{plan.name}</Select.Option>
                        })}
                    </Select> </Col>
                <Col span={1}></Col>
                <Col span={6}>
                    <DatePicker.RangePicker style={{ width: "100%" }}
                        defaultValue={[moment(filters.date[0], dateFormat), moment(filters.date[1], dateFormat)]}
                        onChange={(value, dateString) => setFilters({ ...filters, date: dateString ? dateString : ['', ''] })}
                        format={displayDateFormat}
                    /></Col>
            </Row>
            <Table
                dataSource={orders}
                pagination={pagination}
                onChange={handleTableDataChange}
            >
                <Column title="ID" dataIndex="key" key="key" align="center" />
                <Column title="User" dataIndex="user" key="language" align="center" />
                <Column title="Date" dataIndex="date" key="date" align="center" render={(_, record) => moment(record.date).format(displayDateFormat)} />
                <Column title="Plan" dataIndex="plan" key="plan" align="center"
                />
                <Column title="Amount" dataIndex="amount" key="amount" align="center" render={(amount, record) => `$${(amount / 100).toFixed(2)}`} />
                <Column title="Discount Used" dataIndex="discountCode" key="discountCode" align="center" render={code => code === 'None' ? '-' : code} />
                <Column
                    title="Paid"
                    dataIndex="paid"
                    key="paid"
                    align="center"
                    filters={[{ text: 'Paid', value: true }]}
                    onFilter={(value, record) => record.paid === value}
                    render={paid => paid ? <CheckCircleFilled /> : <CloseCircleOutlined />}
                />
                <Column title="Actions" align="center" render={(_, record) => record.paid ? '-' : <a onClick={() => recheckPayment(record.key)} disabled={loading}>Recheck Status</a>} />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'orders')} />
        </div>
    )
}

export default ManageOrders
