import { Table, Select, Row, Col, DatePicker, Modal, Input, notification } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { CloudDownloadOutlined } from '@ant-design/icons'
import moment from 'moment';

import { Context } from '../../context'
import { Fetch, Post } from '../../fetch'
import { convertIdToKey, convertSubscriptions, handleExportClick } from '../../utils'

const { Column } = Table
const { Option } = Select

const dateFormat = 'MM-DD-YYYY HH:mm:ss'

const ManageUsers = () => {
    const { token, apiUrl, superAdmin } = useContext(Context)

    const [users, setUsers] = useState([])
    const [user, setUser] = useState("")
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
    const [searchValue, setSearchValue] = useState()
    const [filters, setFilters] = useState({ date: ['', ''], activeSubscription: '' })
    const [modalVisible, setModalVisible] = useState(false)
    const [reason, setReason] = useState("")

    useEffect(() => {
        Fetch(`${apiUrl}/users`, token, (result) => {
            const users = convertSubscriptions(convertIdToKey(result.users))
            users.forEach(user => {
                if (!user.mobile) {
                    user.mobile = "-"
                }

                if (!user.email) {
                    user.email = "-"
                }
            })
            setUsers(users)
            setPagination(p => {
                return {
                    ...pagination,
                    total: result.total
                }
            })
        })
    }, [apiUrl, token])

    useEffect(() => {
        let url;
        const startDate = moment(filters.date[0]).isValid() ? moment(filters.date[0]).format(dateFormat) : null
        const endDate = moment(filters.date[1]).isValid() ? moment(filters.date[1]).format(dateFormat) : null
        if (startDate && endDate && filters.activeSubscription) {
            url = `${apiUrl}/users?startDate=${startDate}&endDate=${endDate}&activeSubscription=${filters.activeSubscription}`
        } else if (filters.activeSubscription) {
            url = `${apiUrl}/users?activeSubscription=${filters.activeSubscription}`
        } else if (startDate && endDate) {
            url = `${apiUrl}/users?startDate=${startDate}&endDate=${endDate}`
        } else {
            url = `${apiUrl}/users?current=${pagination.current}&size=${pagination.pageSize}`
        }

        Fetch(url, token, result => {
            const users = convertSubscriptions(convertIdToKey(result.users))
            users.forEach(user => {
                if (!user.mobile) {
                    user.mobile = "-"
                }

                if (!user.email) {
                    user.email = "-"
                }
            })
            setUsers(users)
            setPagination(p => {
                return {
                    ...pagination,
                    total: result.total
                }
            })
        })
    }, [apiUrl, token, filters, modalVisible])

    const handleSearchChange = (searchText) => {
        setSearchValue(searchText)
    }

    const handleTableDataChange = (paginationParam) => {
        let url;
        const startDate = moment(filters.date[0]).isValid() ? moment(filters.date[0]).format(dateFormat) : null
        const endDate = moment(filters.date[1]).isValid() ? moment(filters.date[1]).format(dateFormat) : null
        if (startDate && endDate && filters.activeSubscription) {
            url = `${apiUrl}/users?startDate=${startDate}&endDate=${endDate}&activeSubscription=${filters.activeSubscription}&current=${paginationParam.current}&size=${paginationParam.pageSize}`
        } else if (filters.activeSubscription) {
            url = `${apiUrl}/users?activeSubscription=${filters.activeSubscription}&current=${paginationParam.current}&size=${paginationParam.pageSize}`
        } else if (startDate && endDate) {
            url = `${apiUrl}/users?startDate=${startDate}&endDate=${endDate}&current=${paginationParam.current}&size=${paginationParam.pageSize}`
        } else {
            url = `${apiUrl}/users?current=${paginationParam.current}&size=${paginationParam.pageSize}`
        }

        Fetch(
            url,
            token,
            (result) => {
                const users = convertSubscriptions(convertIdToKey(result.users))
                users.forEach(user => {
                    if (!user.mobile) {
                        user.mobile = "-"
                    }

                    if (!user.email) {
                        user.email = "-"
                    }
                })
                setUsers(users)
                setPagination({ ...paginationParam, total: result.total })
            }
        )
    }

    const handleSearch = (searchText) => {
        if (searchText) {
            Fetch(
                `${apiUrl}/users?searchText=${searchText}`,
                token,
                (result) => {
                    const users = convertSubscriptions(convertIdToKey(result.users))
                    users.forEach(user => {
                        if (!user.mobile) {
                            user.mobile = "-"
                        }

                        if (!user.email) {
                            user.email = "-"
                        }
                    })
                    setUsers(users)
                }
            )
        }
    }

    const cancelSubscription = () => {
        try {
            if (!reason) {
                notification.error({
                    message: 'Cancellation unsuccessful',
                    description: 'reason is mandatory'
                })
                return
            }
            console.log(user)
            Post(
                `${apiUrl}/users/${user}/cancel_user_subscription_by_admin`,
                token,
                { 'reason': reason },
                (result) => {
                    if (result.success) {
                        notification.success({
                            message: 'Success',
                            description: 'Subscription cancelled successfully'
                        })
                        setFilters({ date: ['', ''], activeSubscription: '' })
                        setReason("")
                    }
                },
                () => {
                    notification.error({
                        message: 'Error removing subscription',
                        description: 'Please check console for more details'
                    })
                    setReason("")
                }
            )
        } finally {
            setReason("")
            setModalVisible(false)
        }
    }

    return (
        <div className="manage-section-wrapper">
            <Row>
                <Col span={6}>
                    <Select
                        placeholder="Search User"
                        showSearch
                        allowClear
                        value={searchValue}
                        onChange={handleSearchChange}
                        onSearch={handleSearch}
                        notFoundContent={''}
                        onClear={() => handleTableDataChange({ current: 1, pageSize: 10 })}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={1} />
                <Col span={4}>
                    <Select
                        placeholder="Subscription"
                        allowClear
                        onChange={(e) => setFilters({ ...filters, activeSubscription: e })}
                        notFoundContent={''}
                        onClear={() => setFilters({ ...filters, activeSubscription: '' })}
                        style={{ width: '100%' }}
                    >
                        <Option value={"true"}>Yes</Option>
                        <Option value={"false"}>No</Option>
                    </Select>
                </Col>
                <Col span={1} />
                <Col span={6}>
                    <DatePicker.RangePicker
                        format={dateFormat}
                        onChange={(_, dateString) => setFilters({ ...filters, date: dateString ? dateString : ['', ''] })}
                    />
                </Col>
            </Row>
            <Table
                dataSource={users}
                pagination={pagination}
                onChange={handleTableDataChange}
            >
                <Column title="Name" dataIndex="name" key="name" align="center" />
                <Column title="Email" dataIndex="email" key="email" align="center" />
                <Column title="Mobile" dataIndex="mobile" key="mobile" align="center" />
                <Column title="Created At" dataIndex="createdAt" key="createdAt" align="center" render={(_, record) => moment(record.createdAt).format(dateFormat)} />
                <Column title="Subscription" dataIndex="subscription" key="subscription" align="center" render={(_, record) => record.activeSubscription ? record.subscription : '-'} />
                <Column title="Actions" dataIndex="actions" key="actions" align="center" render={(_, record) => (superAdmin && record.activeSubscription) ? <a onClick={() => { setUser(record.key); setModalVisible(true) }}>Cancel Subscription</a> : '-'} />
            </Table>
            <CloudDownloadOutlined className="export-btn-only" onClick={() => handleExportClick(apiUrl, token, 'users')} />
            <Modal
                title="Enter reason for cancellation"
                visible={modalVisible}
                onOk={() => cancelSubscription()}
                onCancel={() => { setReason(""); setModalVisible(false) }}
                maskClosable={false}
                okText="Confirm Cancellation"
                cancelText="go back"
            >
                <Input onChange={e => setReason(e.target.value)} />
            </Modal>
        </div>
    )
}

export default ManageUsers
